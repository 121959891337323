declare const window: MyWindow;

import {ErrorMessage, HTMLCustomInputElement, ValidationRules} from '../models/validation';
import {addClass, removeClass} from '../utils';
import {stepsState} from '../steps/steps';

/**
 *
 * Function use to validate inputs values
 *
 * All the inputs with the attribute : 'validType' are validated
 * The validType can be : password, newPassword, email, client or date
 * If the validType is not in this list, we just check if the input is not empty
 *
 * Rules are defined in a specific file : 'validation-rules'
 * This file can be override by each realm
 *
 * We can specify multiples error messages for each input
 *
 * You can find example in the inputs template file : inputs.ftl
 *
 */
const inputValidation = {
    vm: this,

    // Global container of the current form
    container: document,

    // Inputs required in the current form not validated yet
    invalidInputs: <string[]>[],

    // Delay between keypress and input validation
    delay: 500,

    // Input attribute checked to enable input validation
    attribute: "validType",

    // Rules used to validate inputs value
    validationRules: null,

    // Object to store debounceTime for multiple inputs at the same time
    debounceTime: {},

    // Check if the value is present
    // Default check if no override method is declared in the file validation-rules
    checkIfEmpty: (input: HTMLInputElement): ErrorMessage[] => {
        const showError = input ? input.value == null || input.value == "" : true;
        return [{showError, messageSuffix: "Message"}];
    },

    showHideInputError: (inputInError: boolean, showError: boolean, input: HTMLInputElement, messageSuffix: string) => {
        var id = input.id + messageSuffix;
        var message = document.getElementById(id);

        if (inputValidation.debounceTime[id]) {
            // Store in input + message avoid clear timeout when multiple message
            clearTimeout(inputValidation.debounceTime[id]);
        }

        //const parentElement: any = input.parentNode;
        if (message && showError) {
            inputValidation.debounceTime[id] = setTimeout(() => {
                addClass(input, "is-invalid");
                removeClass(message, "d-none");

                // We remove keycloak message error to display only input validation errors
                inputValidation.removeKcError();
            }, inputValidation.delay);

        } else if (message) {
            inputValidation.debounceTime[id] = setTimeout(() => {
                if (!inputInError) {
                    removeClass(input, "is-invalid");
                }
                addClass(message, "d-none");
            }, inputValidation.delay);
        }
    },

    // Remove keycloak message error
    removeKcError: () => {
        const errorMessages = document.getElementsByClassName("kc-error-message");
        if (errorMessages && errorMessages[0]) {
            errorMessages[0].remove();
        }
    },

    // Remove or push input in the list of invalid inputs
    checkInputValidation: (isInError: boolean, inputId: string) => {
        if (!inputId) {
            return;
        }
        const input = inputValidation.container.getElementById(inputId)
        if (input) {
            const step = input.closest(".form-step");
            if (step) {
                inputId += step.id
            }
        }
        const index = inputValidation.invalidInputs.indexOf(inputId);
        if (isInError && index == -1) {
            inputValidation.invalidInputs.push(inputId);
        } else if (!isInError && index > -1) {
            inputValidation.invalidInputs.splice(index, 1);
        }

        inputValidation.checkIfSubmitBtnDisabled();
        inputValidation.checkIfOtherPasswordFieldsAreEnabled();
    },

    // Check if the two inputs values are equals
    checkInputsEquals: (inputId1: string, inputId2: string) => {
        const input1 = <HTMLCustomInputElement>document.getElementById(inputId1);
        const input2 = <HTMLCustomInputElement>document.getElementById(inputId2);

        if (input1 != null && input2 != null && (input1.value || input1.dirty) && (input2.value || input2.dirty)) {
            // Set dirty if input modified
            input1.dirty = true;
            input2.dirty = true;

            const areEquals = input1.value === input2.value;
            inputValidation.showHideInputError(!areEquals, !areEquals, input1, "EqualMessage");
            inputValidation.showHideInputError(!areEquals, !areEquals, input2, "EqualMessage");
            inputValidation.checkInputValidation(!areEquals, input1.id);
            inputValidation.checkInputValidation(!areEquals, input2.id);
        }
    },

    // Check if the validation matches at least one of the 3 types of client identifier
    checkClient: (input: HTMLInputElement): ErrorMessage[] => {
        let messages: ErrorMessage[] = [{showError: false, messageSuffix: "Message"}];
        let emailErrorMessage: ErrorMessage[] = inputValidation.validationRules.checkEmail(input);
        let phoneNumberErrorMessage: ErrorMessage[] = inputValidation.validationRules.checkPhoneNumber(input);
        let clientIdentifierErrorMessage: ErrorMessage[] = inputValidation.validationRules.checkClientIdentifier(input);

        // At least one validation has to go through
        if (emailErrorMessage[0].showError && phoneNumberErrorMessage[0].showError && clientIdentifierErrorMessage[0].showError) {
            messages[0].showError = true;
        }

        return messages;
    },

    // Check input with specific rules define in the file validation-rules.js
    // After we check if we display or hide the error message
    // Finally, we check if the submit btn is enabled or disabled
    checkInput: (input: HTMLInputElement) => {

        const type = input.getAttribute("validType");
        // standard message error
        let messages: ErrorMessage[] = [{showError: true, messageSuffix: "Message"}];

        switch (type) {
            case "password":
                messages = inputValidation.checkIfEmpty(input)
                    .concat(inputValidation.validationRules.checkPassword(input));
                break;
            case "calculator":
                messages = inputValidation.checkIfEmpty(input)
                    .concat(inputValidation.validationRules.checkCalculatorPassword(input));
                break;
            case "email":
                messages = inputValidation.validationRules.checkEmail(input);
                if (input.getAttribute('previousValue') !== '') {
                    messages.push(inputValidation.validationRules.checkPreviousValue(input)[0]);
                }
                break;
            case "client":
                messages = inputValidation.checkClient(input);
                break;
            case "date":
                messages = inputValidation.validationRules.checkDate(input);
                break;
            case "newPassword":
                messages = inputValidation.validationRules.checkNewPassword(input);
                break;
            case "otpCode":
                messages = inputValidation.validationRules.checkOTPCode(input);
                break;
            default:
                messages = inputValidation.checkIfEmpty(input);
        }

        if (messages == null || messages.length <= 0) {
            return;
        }

        // Si aucun message, l'input n'est pas en erreur
        var inputInError = false;
        messages.forEach((message: ErrorMessage) => {
            if (message && typeof message.showError == "boolean") {
                inputInError = inputInError || message.showError;
                inputValidation.showHideInputError(inputInError, message.showError, input, message.messageSuffix);
            }
        });
        inputValidation.checkInputValidation(inputInError, input.id);
        inputValidation.checkIfDisplayClearIcon(input);
        return inputInError;
    },

    // Disable or enable the confirm / submit btn
    checkIfSubmitBtnDisabled: function () {
        let btnSubmit: HTMLInputElement = inputValidation.container.querySelector(
            "input[validation]"
        );

        if (btnSubmit != null) {
            btnSubmit.disabled = inputValidation.invalidInputs.length > 0;
        } else {
            btnSubmit = inputValidation.container.querySelector(
                "input[validation" + stepsState.stepIndex + "]"
            );
            if (btnSubmit != null) {
                btnSubmit.disabled = inputValidation.invalidInputs.some((inputId) => inputId.includes('step' + stepsState.stepIndex));
            }
        }
    },

    // Disable or enable password-new and password-confirm
    checkIfOtherPasswordFieldsAreEnabled: function () {
        const otherPasswordFieldsIds = ['password-new', "password-confirm"];
        for (let inputToEnabledId of otherPasswordFieldsIds) {
            const inputToPotentiallyEnabled = <HTMLCustomInputElement>document.getElementById(inputToEnabledId);
            if (!inputToPotentiallyEnabled) {
                return;
            }
            inputToPotentiallyEnabled.disabled = inputValidation.invalidInputs.includes('password');
        }
    },

    // Check if we display the clear icon
    checkIfDisplayClearIcon: function (input) {
        var icon = document.getElementById(input.id + "Clear");

        if (icon == null || input == null) {
            return;
        }

        if (input && input.value !== "") {
            icon.style.display = "block";
        } else {
            icon.style.display = "none";
        }
    },

    // Init the check values
    init: (validationRules: ValidationRules) => {
        inputValidation.validationRules = validationRules;

        const createClosure = function (func, input) {
            return function () {
                // Bind inputValidation context to method call
                func.call(inputValidation, input);
            };
        };

        const inputs = inputValidation.container.getElementsByTagName("input");

        for (let i = 0; i < inputs.length; i++) {

            if (inputs[i].getAttribute(inputValidation.attribute)) {
                let id = inputs[i].id;

                const checKInputClosure = createClosure(
                    inputValidation.checkInput,
                    inputs[i]
                );

                if (inputs[i].value == "") {
                    const step = inputs[i].closest(".form-step");
                    if (step) {
                        id += step.id
                    }
                    inputValidation.invalidInputs.push(id);
                } else {
                    inputValidation.checkIfDisplayClearIcon(inputs[i]);
                    inputs[i].setSelectionRange(
                        inputs[i]?.value?.length,
                        inputs[i]?.value?.length
                    );
                }

                inputs[i].addEventListener("input", checKInputClosure, false);

            }
        }

        inputValidation.checkIfSubmitBtnDisabled();
        inputValidation.checkIfOtherPasswordFieldsAreEnabled();
    }

};

window.togglePassword = (button: HTMLElement, inputId: string) => {
    const input = <HTMLInputElement>document.getElementById(inputId);

    if (button == null || input == null) {
        return;
    }

    const svgUseElement = button.getElementsByTagName('use')?.item(0);

    if (svgUseElement == null) {
        return;
    }
    let href = svgUseElement.getAttribute("href");
    const hrefSplitted = href.split("#");
    href = hrefSplitted[0].concat(hrefSplitted[1] === "icon-eye-show" ? "#icon-eye-hide" : "#icon-eye-show");
    svgUseElement.setAttribute('href', href);

    input.type = input.type !== "password" ? "password" : "text";
};

// Append text to input element + add input password discs
// Use for calculator login form
window.appendTextInputCalculator = (text: string, id: string) => {
    const input = <HTMLInputElement>document.getElementById(id);
    if (input) {
        input.value = input.value + text;
        if (input.value.length >= 1 && input.value.length <= 6) {
            const selectDisc = <HTMLInputElement>document.getElementById(id + "-disc-container").children[input.value.length - 1];
            addClass(selectDisc, "on");
        }

        inputValidation.checkInput(input);
        inputValidation.checkIfDisplayClearIcon(input);
    }
};


// clear text to input element find by id + remove input password discs
window.clearTextInput = (id: string) => {
    const input = <HTMLInputElement>document.getElementById(id);
    if (input) {
        input.value = "";
        const discContainer = document.getElementById(id + "-disc-container");
        if (discContainer) {
            const discs = discContainer.children;
            if (discs) {
                for (let i = 0; i < discs.length; i++) {
                    removeClass(<HTMLInputElement>discs.item(i), "on");
                }
            }
        }
        inputValidation.checkIfDisplayClearIcon(input);
        inputValidation.checkInput(input);
    }
};


window.checkInputsEquals = (inputId1: string, inputId2: string) => {
    inputValidation.checkInputsEquals(inputId1, inputId2);
};

export default inputValidation;
