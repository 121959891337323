import {updateAndCommitTag} from '../index';

/**
 * 
 * Function use to save username in localStorage
 * 
 * If the current form has checkbox named rememberMe
 * We save the username input in localStorage value when the form is submit
 * 
 */
const rememberMe = {

    localStorageId: "keycloak-username",

    getValue: (): string => {
       return localStorage.getItem(rememberMe.localStorageId);
    },

    saveValue: (input: HTMLInputElement, checkBox: HTMLInputElement): void => {
        // On submit
        // If the user set remember me we store the username in the local storage
        // And we send the tag for tracking
        // Otherwise we clear the value
        if(checkBox.checked){
            updateAndCommitTag([{set:'CTA>G-connect>Se souvenir de moi',to:'cta.nom_cta'}],'esc-cta');
            localStorage.setItem(rememberMe.localStorageId, input.value);
        }else{
            localStorage.removeItem(rememberMe.localStorageId);
        }
    },

    init: (): void => {

        const checkBox = <HTMLInputElement>document.getElementById("rememberMe");
        const input = <HTMLInputElement>document.getElementById("username");
        const form = <HTMLFormElement>document.getElementById("kc-form-login");

        if(checkBox && form && input) {
            if (!input.value) {
                input.value = rememberMe.getValue();
            }
            checkBox.checked =  input.value.length > 0;
            form.addEventListener("submit", () => rememberMe.saveValue(input, checkBox), false);
        }
    }
}

export default rememberMe;
